<template>
  <div class=container>
      <br>
      <ul class=projectGrid>
          <li class=projectBox v-for="cate in categories" :key=cate.id >
                <router-link  :to="{path:'/projects/categories', query:{cid:cate.title} }" > 
                    <img class=projIcon :src=cate.photo /> 
                </router-link>
                <router-link class=name :to="{path:'/projects/categories', query:{cid:cate.title} }" >
                    {{cate.title}}
                </router-link>  
          </li>
      </ul>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            categories:this.categories,
            clb :this.clb
        }
    },
    created(){
        // axios.get("http://localhost:8080/api/Statics/categories/catelogs.json").then((res)=>{this.categories=res.data 
        // this.clb = true })
        axios.get("https://cave.cs.columbia.edu/Statics/categories/catelogs.json").then((res)=>{this.categories=res.data 
        this.clb = true })
    }
}
</script>

<style scoped>
.container{
    margin-left:auto;
    margin-right:auto;
    width: 1000px;
    /* width:73%; */
}
/* ul */
.projectGrid{
    margin-top:4.5vh;
    margin-bottom: 10vh;
    width:1200px;
    list-style: none;
    padding-left:0;

}
/* li */
.projectBox{
    margin-top: 4.5vh;
    height:200px;
    width:1200px;
    }
/* img */
.projIcon{
    width:253px;
    height: 190px;
    border-radius: 3px;
    border: 2px solid #9c9797;
    /* box-shadow: 2px 2px 2px 2px #dcdcdc; */
    float:left
}
/* router link */
.name{
    margin-left: 50px;
    margin-top:80px;
    font-size:23px;
    font-weight: 600;
    text-decoration: none;
    color:var(--text-color);
    font-family:'Raleway', sans-serif;
    float:left;
}
.name:hover{
    color: #a5a2a2;
}
</style>